import React from "react";
import ProductCategoryView from "@/views/ProductCategory";

interface Props {
    images: string[];
    currentImageIndex: number;
    dragging: boolean;
    isTransitioning: boolean;
    product: { title: string };
}

const Carousel = ({ images, currentImageIndex, dragging, isTransitioning, product }: Props) => {
    return (
        <div
            className="card-carousel"
            style={{
                display: "flex",
                overflow: "hidden",
                width: "100%",
            }}
        >
            {images.map((img, index) => (
                <ProductCategoryView.Card.Image
                    key={index}
                    src={img}
                    alt={product.title}
                    style={{
                        minWidth: "100%",
                        transition: dragging || !isTransitioning ? "none" : "transform 0.3s ease",
                        transform: `translateX(-${currentImageIndex * 100}%)`,
                    }}
                />
            ))}
        </div>
    );
};

export default Carousel;
