import React, { useEffect, useMemo } from "react";
import { EntityFetcher } from "@/components/Controls";
import ProductCategoryView from "@/views/ProductCategory";
import { BrandEntity, ColorEntity } from "@/api/services/entities/entities";
import { BrandSchema, ColorSchema } from "@/api/services/entities/schemas";
import { useFavourite } from "@/modules/hooks";
import { ProductList, SkuGroup } from "@/api/services/catalog/models/Product.model";
import { plainToClass } from "class-transformer";
import { useState, useRef } from "react";

export type ProductCardProps = {
    product: ProductList;
    skus?: SkuGroup[];
    sortType?: string | string[] | (string | string[])[] | null;
};

const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
const isMouseDevice = () => {
    return !isTouchDevice() || window.matchMedia("(pointer: fine)").matches;
};

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ product, skus, sortType }: ProductCardProps) => {
    const { isFavorite } = useFavourite();
    const isFav = isFavorite(product.id);
    const isTouch = isTouchDevice();
    const isMouse = isMouseDevice();

    const getAvailableColorsWithSkus = (
        skus: SkuGroup[],
        sortType: string | string[] | (string | string[])[] | null | undefined
    ) => {
        if (!skus || skus.length === 0) return [];
        let availableColorsWithSkus = skus
            .filter((sku) => sku.quantity > 0 && sku.attr.color && sku.salePrice)
            .map((sku) => ({ color: sku.attr.color!, sku }));

        availableColorsWithSkus = availableColorsWithSkus.sort((a, b) => {
            switch (sortType) {
                case "-price":
                    return b.sku.salePrice - a.sku.salePrice;
                case "price":
                    return a.sku.salePrice - b.sku.salePrice;
                case "sale": {
                    const discountA = (a.sku.rrcPrice - a.sku.salePrice) / a.sku.rrcPrice;
                    const discountB = (b.sku.rrcPrice - b.sku.salePrice) / b.sku.rrcPrice;
                    return discountB - discountA;
                }
                default:
                    return 0;
            }
        });

        return availableColorsWithSkus.length > 0
            ? availableColorsWithSkus
            : [{ sku: skus.find((sku) => sku.quantity > 0) }];
    };

    const transformedSkus = skus?.map((sku) => plainToClass(SkuGroup, sku));
    // const availableColorsWithSkus = getAvailableColorsWithSkus(transformedSkus || [], sortType);
    // // const firstAvailableSkuImage = availableColorsWithSkus[0]?.sku?.attr?.images?.display?.[0] ?? "";
    // const images = availableColorsWithSkus[0]?.sku?.attr?.images?.display ?? product.image ?? [];
    // const extendedImages = [images[images.length - 1], ...images, images[0]]; // Копируем первую и последнюю картинку
    const availableColorsWithSkus = useMemo(() => getAvailableColorsWithSkus(transformedSkus || [], sortType), [
        skus,
        sortType,
    ]);
    const images = useMemo(() => availableColorsWithSkus[0]?.sku?.attr?.images?.display ?? product.image ?? [], [
        availableColorsWithSkus,
        product,
    ]);
    const extendedImages = useMemo(() => [images[images.length - 1], ...images, images[0]], [images]);

    const [currentImageIndex, setCurrentImageIndex] = useState(isTouch && !isMouse ? 1 : 0);
    // Для сенсорных начинаем со второй картинки (первая — это клон последней)
    const [isHorizontalSwipe, setIsHorizontalSwipe] = useState(false);
    const [startTouchX, setStartTouchX] = useState<number>(0);
    const [startTouchY, setStartTouchY] = useState<number>(0);
    const cardRef = useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState(false); // Флаг, чтобы отслеживать начало/конец свайпа
    // const [currentTouchX, setCurrentTouchX] = useState<number>(0); // Текущая позиция пальца
    const [isTransitioning, setIsTransitioning] = useState(true); // Флаг для управления анимацией

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!cardRef.current) return;

        const { left, width } = cardRef.current.getBoundingClientRect();
        const clientX = e.clientX;
        const relativeX = clientX - left;
        const percentage = relativeX / width;

        if (images.length <= 1) return;

        const clampedPercentage = Math.max(0, Math.min(1, percentage));
        const index = Math.floor(clampedPercentage * images.length);
        setCurrentImageIndex(index % images.length);
    };

    const handleMouseLeave = () => {
        setCurrentImageIndex(0);
    };

    // const handleTouchStart = (e: React.TouchEvent) => {
    //     const touch = e.touches[0];
    //     setStartTouchX(touch.clientX); // Запоминаем начальную позицию пальца
    //     setDragging(true); // Начинаем отслеживание свайпа
    // };
    //
    // const handleTouchMove = (e: React.TouchEvent) => {
    //     if (!dragging) return;
    //     const touch = e.touches[0];
    //     setCurrentTouchX(touch.clientX);
    // };
    //
    // const handleTouchEnd = (e: React.TouchEvent) => {
    //     setDragging(false);
    //
    //     const touchEndX = e.changedTouches[0].clientX;
    //     const deltaX = touchEndX - startTouchX;
    //     const sensitivity = 20; // Порог чувствительности
    //
    //     if (Math.abs(deltaX) > sensitivity) {
    //         setCurrentImageIndex((prevIndex) => prevIndex + (deltaX > 0 ? -1 : 1));
    //     }
    // };
    const handleTouchStart = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        setStartTouchX(touch.clientX); // Запоминаем начальную позицию пальца
        setStartTouchY(touch.clientY); // Запоминаем начальную позицию пальца по Y
        setDragging(true); // Начинаем отслеживание свайпа
        setIsHorizontalSwipe(false); // Сброс флага перед новым свайпом
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!dragging) return;

        const touch = e.touches[0];
        // setCurrentTouchX(touch.clientX);
        // setCurrentTouchY(touch.clientY);

        const deltaX = touch.clientX - startTouchX;
        const deltaY = touch.clientY - startTouchY;

        // Определяем, горизонтальный или вертикальный свайп
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            // Горизонтальный свайп
            setIsHorizontalSwipe(true);
            e.preventDefault(); // Останавливаем скролл
        } else {
            // Вертикальный свайп
            setIsHorizontalSwipe(false);
        }
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        setDragging(false);

        const touchEndX = e.changedTouches[0].clientX;
        const deltaX = touchEndX - startTouchX;
        const sensitivity = 20; // Порог чувствительности

        // Если был горизонтальный свайп
        if (isHorizontalSwipe && Math.abs(deltaX) > sensitivity) {
            setCurrentImageIndex((prevIndex) => prevIndex + (deltaX > 0 ? -1 : 1));
        }
    };

    useEffect(() => {
        if (isMouse) return;

        const preventScroll = (e: TouchEvent) => {
            if (dragging) {
                e.preventDefault(); // Блокируем скролл только при свайпе
            }
        };

        document.addEventListener("touchmove", preventScroll, { passive: false });
        return () => {
            document.removeEventListener("touchmove", preventScroll);
        };
    }, [dragging]);

    useEffect(() => {
        if (isMouse) return;

        if (currentImageIndex === 0) {
            // Отключаем анимацию перед мгновенным переходом
            setTimeout(() => {
                setIsTransitioning(false);
                setCurrentImageIndex(images.length);
            }, 300);
        } else if (currentImageIndex === images.length + 1) {
            setTimeout(() => {
                setIsTransitioning(false);
                setCurrentImageIndex(1);
            }, 300);
        } else {
            setIsTransitioning(true); // Включаем анимацию для обычных переходов
        }
    }, [currentImageIndex]);

    // Выбираем нужные обработчики в зависимости от типа устройства
    const onMouseMove = isMouse ? handleMouseMove : undefined;
    const onMouseLeave = isMouse ? handleMouseLeave : undefined;
    const onTouchStart = isTouch && !isMouse ? handleTouchStart : undefined;
    const onTouchMove = isTouch && !isMouse ? handleTouchMove : undefined;
    const onTouchEnd = isTouch && !isMouse ? handleTouchEnd : undefined;

    return (
        <ProductCategoryView.Card>
            <ProductCategoryView.Card.Wrap
                href={product.slug}
                type="top"
                ref={cardRef}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                {isTouch && !isMouse ? (
                    <ProductCategoryView.Card.Carousel
                        images={extendedImages}
                        currentImageIndex={currentImageIndex}
                        dragging={dragging}
                        isTransitioning={isTransitioning}
                        product={product}
                    />
                ) : (
                    <ProductCategoryView.Card.Image src={images[currentImageIndex]} alt={product.title} />
                )}

                <ProductCategoryView.Card.Labels>
                    {product.attr.labels?.value.map((label, index) => {
                        return (
                            <ProductCategoryView.Card.Labels.Label key={product.attr.labels?.id[index]}>
                                {label}
                            </ProductCategoryView.Card.Labels.Label>
                        );
                    })}
                </ProductCategoryView.Card.Labels>
                <EntityFetcher<ColorSchema, ColorEntity>
                    Model={ColorEntity}
                    queryKey="colors"
                    idsList={
                        availableColorsWithSkus.length > 0
                            ? availableColorsWithSkus
                                  .map(({ sku }) => (sku ? String(sku.attr.color?.id) : null)) // Проверяем наличие sku и color
                                  .filter((id): id is string => id !== null) // Удаляем все null значения и оставляем только строки
                            : []
                    }
                >
                    {({ items }) => {
                        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />;
                    }}
                </EntityFetcher>
                {isFav ? <ProductCategoryView.Card.Favorite /> : null}
            </ProductCategoryView.Card.Wrap>
            <ProductCategoryView.Card.Wrap type="bot">
                <ProductCategoryView.Card.Features>
                    {product.attr.marks?.value.map((mark, index) => (
                        <ProductCategoryView.Card.Features.Feature key={product.attr.marks?.id[index]}>
                            {mark}
                        </ProductCategoryView.Card.Features.Feature>
                    ))}
                </ProductCategoryView.Card.Features>
                <ProductCategoryView.Card.Prices
                    oldPrice={availableColorsWithSkus[0]?.sku?.rrcPrice}
                    price={availableColorsWithSkus[0]?.sku?.salePrice}
                />
                <EntityFetcher<BrandSchema, BrandEntity>
                    Model={BrandEntity}
                    queryKey="brand"
                    idsList={[String(product.attr.brand?.id)]}
                >
                    {({ items }) => {
                        return (
                            <>
                                {items.map((brand) => {
                                    return (
                                        <ProductCategoryView.Card.Brand
                                            key={brand.id}
                                            src={brand.image}
                                            alt={brand.title}
                                            brandTitle={brand.title}
                                        />
                                    );
                                })}
                            </>
                        );
                    }}
                </EntityFetcher>
                {product.attr.titlePrefix && (
                    <span className="catcard__titlePrefix">{product.attr.titlePrefix.value}</span>
                )}
                <ProductCategoryView.Card.Title href={product.slug}>{product.title}</ProductCategoryView.Card.Title>
                <ProductCategoryView.Card.Rating count={product?.review?.count ?? 0} avg={product?.review?.avg ?? 0} />
            </ProductCategoryView.Card.Wrap>
        </ProductCategoryView.Card>
    );
};

export default ProductCard;
