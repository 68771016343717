import { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { parse, stringify, ParsedQuery, exclude, pick } from "query-string";

export type Filter = ParsedQuery<string | string[]>;

export type ChangeFilter = (
    name: string,
    value: string | string[],
    action: "add" | "remove" | "update" | "json",
    forceUpdate?: boolean,
    filterName?: string
) => void;

type UseFiltersOptions = Partial<{
    excludeParseQueryParams: string[];
    otherParseQueryParams: string[];
}>;

const useFilters = (options: UseFiltersOptions = {}) => {
    const { excludeParseQueryParams = [], otherParseQueryParams = [] } = options;

    const history = useHistory();
    const { search } = useLocation();

    const queryFilters: Filter = parse(exclude(search, excludeParseQueryParams), { arrayFormat: "comma" });
    const otherQuery = parse(pick(search, otherParseQueryParams));

    const [activeFilters, setActiveFilters] = useState<Filter>(queryFilters);
    const [filters, setFiters] = useState<Filter>(activeFilters);
    const [jsonFilters, setJsonFilters] = useState<any>({});
    const [update, setUpdate] = useState<boolean>(false);

    const handleAdd = useCallback((name: string, value: string | string[]) => {
        setFiters((prev) => {
            const newFilters = { ...prev };
            const filter = newFilters[name];

            if (!filter) {
                newFilters[name] = value;
                return newFilters;
            }

            if (filter instanceof Array) {
                newFilters[name] = filter.concat(value);
            } else {
                newFilters[name] = [filter, value];
            }
            return newFilters;
        });
    }, []);

    const handleRemove = useCallback((name: string, value: string | string[]) => {
        setFiters((prev) => {
            const newFilters: Filter = { ...prev };
            const filter = newFilters[name];

            if (name === "sale_price") {
                delete newFilters[name];
                return newFilters;
            }

            if (filter instanceof Array && filter.length > 1) {
                newFilters[name] = filter.filter((v) => v !== value);
            } else {
                delete newFilters[name];
            }

            return newFilters;
        });
    }, []);

    useEffect(() => {
        const queryFilters: any = parse(pick(search, ["attr", "sku_attr"]));
        const attr: any = queryFilters["attr"] ? JSON.parse(queryFilters?.["attr"]) : {};
        const skuAttr: any = queryFilters["sku_attr"] ? JSON.parse(queryFilters?.["sku_attr"]) : {};
        const result: any = {};
        result["attr"] = attr;
        result["sku_attr"] = skuAttr;
        setJsonFilters(result);
    }, [search]);

    const handleJson = useCallback(
        (filterName: string, name: string, value: string | string[]) => {
            if (!jsonFilters[filterName]) {
                jsonFilters[filterName] = {};
            }
            if (jsonFilters[filterName][name]) {
                if (jsonFilters[filterName][name].value.includes(value)) {
                    jsonFilters[filterName][name].value = jsonFilters[filterName][name].value.filter(
                        (item) => item !== value
                    );
                    if (jsonFilters[filterName][name].value.length === 0) {
                        delete jsonFilters[filterName][name];
                        if (Object.keys(jsonFilters[filterName]).length === 0) {
                            delete jsonFilters[filterName];
                        }
                    }
                } else {
                    jsonFilters[filterName][name].value = [...jsonFilters[filterName][name].value, value];
                }
            } else {
                jsonFilters[filterName][name] = { lookup_expr: "in", value: [value] };
            }
            setJsonFilters(jsonFilters);
        },
        [jsonFilters]
    );

    const handleChange: ChangeFilter = useCallback(
        (name, value, action, forceUpdate = false, filterName) => {
            switch (action) {
                case "add": {
                    handleAdd(name, value);
                    break;
                }
                case "update": {
                    handleRemove(name, value);
                    handleAdd(name, value);
                    break;
                }
                case "remove": {
                    handleRemove(name, value);
                    break;
                }
                case "json": {
                    if (filterName) {
                        handleJson(filterName, name, value);
                    }
                    break;
                }
            }

            if (forceUpdate) {
                setUpdate(true);
            }
        },
        [handleAdd, handleRemove, jsonFilters]
    );

    const handleApply = useCallback(() => {
        setActiveFilters(filters);
        const jsonQuery = {};
        Object.keys(jsonFilters).map((key) => {
            jsonQuery[key] = JSON.stringify(jsonFilters[key]);
        });

        const { page, ...otherQueryWithoutPage } = otherQuery;
        history.replace({
            search: stringify(
                { ...filters, page: 1, ...otherQueryWithoutPage, ...jsonQuery },
                { arrayFormat: "comma" }
            ),
        });
    }, [history, filters, otherQuery, jsonFilters]);

    const handleClear = useCallback(() => {
        setActiveFilters({});
        setFiters({});
        setJsonFilters({});
        history.replace({ search: stringify(otherQuery) });
    }, [history, otherQuery]);

    useEffect(() => {
        if (update) {
            handleApply();
            setUpdate(false);
        }
    }, [update, handleApply]);

    const presentFilters = Object.entries(activeFilters).map((el) => ({ name: el[0], value: el[1] }));

    return {
        activeFilters: presentFilters.filter(
            (el) => el.name !== "sale_price" && el.name !== "max_sale_price" && el.name !== "min_sale_price"
        ),
        filters,
        jsonFilters,
        handleClear,
        handleChange,
        handleApply,
        hasFilters: !!presentFilters.length,
    };
};

export default useFilters;
