import React, { PropsWithChildren, forwardRef, Ref } from "react";

export type WrapProps = PropsWithChildren<{
    type?: "top" | "bot";
    href?: string;
}> &
    React.HTMLAttributes<HTMLElement>;

const AnchorWrap = forwardRef<HTMLAnchorElement, WrapProps>(({ children, href, ...props }, ref) => {
    return (
        <a className="catcard__topwrap" href={href} ref={ref} {...props}>
            {children}
        </a>
    );
});

const DivWrap = forwardRef<HTMLDivElement, WrapProps>(({ children, ...props }, ref) => {
    return (
        <div className="catcard__botwrap" ref={ref} {...props}>
            {children}
        </div>
    );
});

const Wrap = forwardRef<HTMLElement, WrapProps>(
    ({ children, href, type, onMouseMove, onMouseLeave, onTouchStart, onTouchMove, onTouchEnd }, ref) => {
        const handleMouseMove = (e) => {
            onMouseMove?.(e);
        };
        const handleMouseLeave = (e) => {
            onMouseLeave?.(e);
        };
        const handleTouchStart = (e) => {
            const touch = e.touches[0];
            onTouchStart?.(e);
        };
        const handleTouchMove = (e) => {
            const touch = e.touches[0];
            onTouchMove?.(e);
        };
        const handleTouchEnd = (e) => {
            onTouchEnd?.(e);
        };

        if (type === "top") {
            return (
                <AnchorWrap
                    ref={ref as Ref<HTMLAnchorElement>}
                    href={href}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {children}
                </AnchorWrap>
            );
        }

        return (
            <DivWrap ref={ref as Ref<HTMLDivElement>} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                {children}
            </DivWrap>
        );
    }
);

export default Wrap;
