import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { Config } from "@/modules/constants";
import { PaginatedQueryResult } from "@/api/shared/models";
import { SearchProductModel } from "@/api/services/common/models/SearchProduct.model";
import { ProductList } from "@/api/services/catalog/models/Product.model";
import { EntitiesQueryParams } from "@/api/services/entities";

class SearchApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    // search = async (params: { q: string }): Promise<SearchProductModel[]> => {
    //     const { data } = await this.http.get("/search/entity/", { params });
    //     console.log("API response for getProductsSearch:", data); // Логируем ответ API
    //     return this.plainToClassArray(SearchProductModel, data.data);
    // };
    search = async (params: {
        q: string;
        page?: number;
        page_size?: number;
    }): Promise<PaginatedQueryResult<SearchProductModel>> => {
        const { data } = await this.http.get("/search/entity/", { params });
        console.log("Ответ API поиск:", data);
        return this.plainToClassFromExist(new PaginatedQueryResult(SearchProductModel), {
            count: data.count,
            results: data.results,
        });
    };
}

export default new SearchApiService({
    baseURL: Config.CMS_API_URL,
});
